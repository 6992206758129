@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap);
* {
  box-sizing: border-box;
}
body {
  background: #fff !important;
  width: 100%;
  display: flex;
  font-weight: 100;
  font-family: "Fira Sans", sans-serif;
  font-size: 0.7rem !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500 !important;
  font-family: "Fira Sans", sans-serif;
}
label,
span {
  font-weight: 100 !important;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.maindiv {
  background: #79b91c;
  padding: 10px 10px;
  height: 100%;
}
.maindivR3 {
  background: #0079c2;
  padding: 10px 10px;
  height: 100%;
}
.mainHeading {
  padding-left: 30px;
  padding-top: 5px;
}
.bunzltitle {
  font-size: 15px;
}
.infologo {
  float: right;
}
.divlogin {
  background: white;
}
.card-header {
  padding: 0.6rem 0.6rem !important;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-title {
  margin-bottom: 0.3rem !important;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.1rem !important;
  font-size: 0.7rem;
}
.card-body_login {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.5rem !important;
  font-size: 0.9rem;
}
.divcard {
  background: white;

  border: 5px solid #79b91c !important;
  width: 500px;
}
.loginContent {
  padding-left: 50px;
}
.divcardR3 {
  background: white;

  border: 5px solid #0079c2 !important;
  width: 520px;
}
.logintitle {
  background: #79b91c !important;
}
.logintitleR3 {
  background: #0079c2 !important;
}
.divContainer {
  background: white;
  display: flex;
  overflow: "hidden";
  justify-content: center;
  margin: 20px !important;
  padding-top: 2%;
  height: 90%;
}
.divContainerGrid {
  background: #79b91c;
  display: flex;
  overflow: "hidden";
  justify-content: center;
  margin: 20px;
  border: 1px solid silver;
  height: 85%;
}
.divContainerGridR3 {
  background: #0079c2;
  display: flex;
  overflow: "hidden";
  justify-content: center;
  margin: 20px;
  border: 1px solid silver;
  height: 85%;
}
.footercolor {
  background-color: #1d2f68;
}
.footercolorR3 {
  background-color: #000000;
}

.LinkBar {
  color: #1d2f68;
  background-color: #79b91c;
  padding: 0px 0px;
  font-weight: 900;
  max-width: -webkit-fit-content;
  max-width: fit-content;
}
.LinkBarR3 {
  color: #000000;
  background-color: #0079c2;
  padding: 0px 0px;
  font-weight: 900;
  max-width: -webkit-fit-content;
  max-width: fit-content;
}

.LinkButton {
  color: #1d2f68;
}

.selectedLink {
  color: #fff !important;
}

.nav-item {
  height: 30px !important;
}
.nav-hide {
  display: none;
}

.nav-show {
  display: block;
}

.nav-line {
  padding-top: 5px;
  color: #fff;
}

.costomerInfo {
  padding: 6px 18px;
  font-size: small;
  color: #1d2f68;
  font-weight: 500;
}
.logoess {
  padding: 18px;
}

.zeroPadding {
  padding: 0px;
}
.footerBunzl {
  /* padding: 15px; */
  padding-bottom: 1px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.contentPage {
  /* height: auto; */
  background-color: #79b91c;
  padding-top: 4px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-bottom: 2px !important;
  border-top: solid;
  height: 98%;
  position: fixed;
}
.contentPageR3 {
  background-color: #0079c2;
  padding-top: 4px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-bottom: 2px !important;
  border-top: solid;
  height: 98%;
  position: fixed;
}
.footerBackGround {
  background-color: #1d2f68;
  color: #fff;
}
.footerBackGroundR3 {
  background-color: #000000;
  color: #fff;
}
.contactpopupfont {
  color: #1d2f68;
  font-weight: bold;
}

.filterControls {
  font-weight: bold;
  font-size: 0.7rem;
  line-height: normal;
  border-radius: 0.2em;
}

.filterButtons {
  width: 80px;
  font-weight: bold !important;
  font-size: 0.7rem !important;
}
.filterButtonsClear {
  width: 100px;
  font-weight: bold !important;
  font-size: 0.7rem !important;
}
.cardNoBorder {
  border: none !important;
}

.gridheader {
  background-image: linear-gradient(#1d2f68, #0072cc);
  color: #fff;
  font-size: 0.7rem !important;
  text-align: left !important;
}
.gridheaderR3 {
  background-image: linear-gradient(#000000, #0072cc);
  color: #fff;
  font-size: 0.7rem !important;
}

.filterBody {
  background-image: linear-gradient(#1d2f68, #0072cc);
  color: #fff;
  padding: 5px;
  /* width: 1090px; */
}
.filterBodyR3 {
  background-image: linear-gradient(#000000, #0072cc);
  color: #fff;
  padding: 5px;
  /* width: 1000px */
}

.ag-paging-panel {
  height: 30px !important;
}
.gridMain .ag-row-hover {
  background-color: #9be133 !important;
}
.gridMainR3 .ag-row-hover {
  background-color: #0079c2 !important;
}
.apGrid .ag-row-hover {
  background-color: #9be133 !important;
  cursor: pointer;
}
.apGridR3 .ag-row-hover {
  background-color: #0079c2 !important;
  cursor: pointer;
}
.apGrid .ag-center-cols-viewport {
  overflow-x: hidden;
}
.apGridR3 .ag-center-cols-viewport {
  overflow-x: hidden;
}
.apGrid .ag-row-selected {
  background-color: #79b91c !important;
}
.apGridR3 .ag-row-selected {
  background-color: #0079c2 !important;
}
.apGrid .ag-root-wrapper{
  border: 0px !important;
}

.apGridR3 .ag-root-wrapper{
  border: 0px !important;
}
.total-row {
  background-color: #808080 !important;
  border-top: solid !important;
  font-weight: bold !important;
  padding-bottom: 0.5rem;
}
.ag-header-cell-label {
  justify-content: center;
}
.searchFilter {
  display: none !important;
}
.img {
  display: block !important;

  background-image: url(/static/media/magGlass.fae5a215.jpg);
  background-repeat: no-repeat;
}

.empListText {
  margin-left: 500px;
  color: #fff;
  margin-top: 5px;
}

.previoustBtn {
  margin-left: "400px";
}

.custListText {
  margin-left: 20px;
  color: #fff;
  margin-top: 10px;
}
.ag-theme-alpine .ag-row {
  font-size: 0.7rem !important;
  font-weight: 500 !important;
}

.control-inline-label {
  padding-left: calc(0.375rem + 20px);
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  color: #fff;
  background-color: grey !important;
  margin: 0px 2px 0px 2px !important;
}
.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
  background-color: #fff !important;
  margin: 0px 2px 0px 2px !important;
}

.form-check-label {
  font-weight: 500 !important;
}

.dropdown-item:hover {
  color: #16181b !important;
  text-decoration: none;
  background-color: #79b921 !important;
}
.ag-header-cell-text {
  white-space: normal !important;
}
.modal-content
{
  opacity: 0.9;
  background-color: #91a5af !important;
}

.essLoading .modal-content
{
  opacity: 0.9;
  background-color: #007bff !important;
}
.ag-header-cell-label {
  text-overflow: clip;
  overflow: visible;
  white-space: normal;
}
.App {
  text-align: center;
}

.App-logo {
  background-image: url(data:image/gif;base64,R0lGODlhNwAtANUiAHm5HB0vaP///x4waR0waR4sZSc6ch4uZx4tZffz9x4qYyQ2bzVDeh4rZB4vaFFbjEFNgkdShqOkwtva5x81bWZmmeno8czMzB8nYH6AqR0xa4+QtB0waB4yax4xax8xaR8lXh0yawAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAACIALAAAAAA3AC0AAAb/wIBQSBgaCUWjciBkJjnM4SA6DYiuWGw0WUxWicpkoBptfsWBYnY9NjrTzK2y/T6270jnOjvomu1DaHNlYwRxYGNMe1iIaUR9h3dOfUiNUpeOVosiblRFVIF3f02PbmKKm6dhhm50TZV9rXOOA5siZKKJhbJ2HLNwhGqbsVKCgJW/cIafSr62sUW+pIRTaJHIX0uZz8ydlgEcp2WflI6CwouikWbE3a2Eg6K2u/D1aGL3UtlltZuAaV7chZomaVYcQ/MCHBDiwMEBhw8PLJQoRCJEIxIpNgywUaFFibYKIEAgUiTJkwVSkkxpkqXKkiNLumS50paAmzhz6tzJs6dP/wE2fwodmjMBT1sMDChdwOCBBAEQFiyIIGCCUgMPki54cFOCUgYRrord+mCBAQYWbtqiYLJBAwwNJDBQoIBB1Q4NFEBgi0BBBgESFBTQwAAEBgUj+xq48AAuhbRANy04oKDCBQ0HMFSAkNLuBAoHCkRdiGDABAkFHFB4ACFCBA0OETyN0ODAAsi2JovOoKGAAQmcC3gGLXqBAwKUH2wY4PCpgA0FKFe4SfvAY7WShSgA0aDAAwsMRg4/gGB0iPAHGHg48OHpBQ8IGkQwKoA2guuRF02WL4G2gggMhGbXBR0c0EBUDhSQwQOCEeBAewkwEN1iAhhVHQUTJJBAbtIxpuLAAR4w0EAfEDCgXQUGZLaBBQsUwNAFFSig0AIQlLhgA2lctVZJAQiGgVMfIEYXAhgwcFkBGPy1gWAuRhCASX1t9wCDIyngFlJLmQVBBmkxtkAHHRhQwQRWGbDABjdVYJZYbIqpJpu2aCgnfTlZQCadchY1554aVshnUEQFKih2iwBg6KGIJqrooow2CoAtjkYq6aSQTmrppYdWiummkWrK6aeKegrqqI9uQuqppRaK6qiiroppq65aCmuskl5hBBa0cmqrEJxckeumvfKqya+vWiGsr8TK2isnwyZL6a6aiBAEADs=);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0rem;
}

.App-link {
  color: #09d3ac;
}


/* * {
margin: 0;
padding: 0;
box-sizing: border-box;
font-family: "Roboto", sans-serif;
outline: none;
}
html,body{
  width: 100%;
  height: 100%;
}
body {
background-color: #fff;
} */
/* .login-page{
  border-top:8px solid #79B921;
}
.form-holder h4 {
  text-align: center;
  padding: 10px 15px 10px;
  margin: 0px;
  font-weight: 700;
  color: #212529;
  font-size: 24px;
  text-transform: uppercase;
  border-bottom: 3px solid #79b921;
}
.login-box{
position: absolute;
width: 400px;
height: auto;
left:50%;
top:50%;
transform: translate(-50%,-50%);
}
.form-holder{
  border:3px solid #79B921;
  border-radius: 0px;
}
.logo-section{
  text-align: center;
}
.logo-section img{
  width: 80px;
  margin: auto;
}
.logo-section small{
  display: block;
  text-align: center;
  margin: 5px 0px 20px;
  font-size: 14px;
  font-weight: 600;
  color: #002d57;
}
.login-form {
  padding: 10px 15px;
}
.login-form label{
  margin: 0px;
  font-size: 14px;
  color: #666;
}
.login-form .form-group{
  margin-bottom: 10px;
  position: relative;
}
.login-form .form-group .form-control{
  border-radius: 0px;
}
.login-form #pwd{
  padding-right: 50px;
}
.login-form .btn-container{
  padding:0px 15px
}
.login-form .form-group button{
  width:48%;
  text-transform: uppercase;
  float: left;
}
.login-form .btn-primary{
  margin: 0px 1% 0px 0px;
}
.btn-primary{
  background-color: #002d57;
  border:1px solid #002d57;
  color: #fff;
} 
.login-form .btn-o{
  margin: 0px 0px 0px 1%;
}
 .btn-o{
  border:1px solid #002d57;
  color: #002d57;  
} 
.copyright {
  font-size: 12px;
  text-align: center;
  color: #999;
  padding: 20px 0px 0px;
} 
#show{
  opacity: 0;
}
label[for="show"] {
  position: absolute;
  right: 10px;
  bottom: 32px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  color: #002d57;
}

/*inner pages */
.logo img {
  max-width: 100%;
  width: 55px;
  margin-top: 10px;
}
header {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
nav.navbar.navbar-expand-sm.bg-success.navbar-dark {
  display: inline-block;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #79b921 !important;
  padding: 0px 12px;
}
nav.navbar.navbar-expand-sm.bg-success.navbar-dark.r3 {
  display: inline-block;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #0079c2 !important;
  padding: 0px 12px;
}

nav.navbar.navbar-expand-sm.bg-success.navbar-dark .navbar-nav {
  display: inline-block;
}
nav.navbar.navbar-expand-sm.bg-success.navbar-dark ul li {
  display: inline-block;
}
nav.navbar.navbar-expand-sm.bg-success.navbar-dark ul li a {
  position: relative;
  color: #002d57;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.7rem;
  padding: 5px 8px;
}
nav.navbar.navbar-expand-sm.bg-success.navbar-dark.r3 ul li a {
  position: relative;
  color: #000000;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.7rem;
  padding: 5px 8px;
}
nav.navbar.navbar-expand-sm.bg-success.navbar-dark ul li a:hover {
  position: relative;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.7rem;
  padding: 5px 8px;
}
nav.navbar.navbar-expand-sm.bg-success.navbar-dark ul li a:after {
  content: "";
  position: absolute;
  width: 2px;
  right: 0;
  top: 8px;
  height: 0.7rem;
  background-color: #fff;
}
nav.navbar.navbar-expand-sm.bg-success.navbar-dark ul li:last-child a:after {
  width: 0px;
  background-color: #79b921;
}
.nav-section small {
  display: block;
  color: #999;
  margin-top: 5px;
}
.chat {
  margin-top: 10px;
}
.chat-btn {
  background-color: #d7edc6;
  position: relative;
  padding: 0px 10px 0px 50px;
  border-radius: 10px;
  float: right;
}
.chat-btn img {
  position: absolute;
  left: 6px;
  top: 8px;
}
.chat-btn p {
  text-align: left;
  text-transform: uppercase;
  border-left: 1px solid #bfccb5;
  padding: 7px 10px;
  margin: 0px;
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
  color: #002d57;
}
.nav-hide {
  display: none !important;
}

.nav-show {
  display: block;
}

.logoColSpace {
  margin-top: 12px;
  margin-right: 2px;
  padding: 0px;
}
.headerColSpace {
  margin-left: -40px;
  padding: 0px;
}

.loginBox {
  background-color: #79b921 !important;
  padding: 10px;
  margin: 10%;
}

