@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

* {
  box-sizing: border-box;
}
body {
  background: #fff !important;
  width: 100%;
  display: flex;
  font-weight: 100;
  font-family: "Fira Sans", sans-serif;
  font-size: 0.7rem !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500 !important;
  font-family: "Fira Sans", sans-serif;
}
label,
span {
  font-weight: 100 !important;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.maindiv {
  background: #79b91c;
  padding: 10px 10px;
  height: 100%;
}
.maindivR3 {
  background: #0079c2;
  padding: 10px 10px;
  height: 100%;
}
.mainHeading {
  padding-left: 30px;
  padding-top: 5px;
}
.bunzltitle {
  font-size: 15px;
}
.infologo {
  float: right;
}
.divlogin {
  background: white;
}
.card-header {
  padding: 0.6rem 0.6rem !important;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-title {
  margin-bottom: 0.3rem !important;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.1rem !important;
  font-size: 0.7rem;
}
.card-body_login {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.5rem !important;
  font-size: 0.9rem;
}
.divcard {
  background: white;

  border: 5px solid #79b91c !important;
  width: 500px;
}
.loginContent {
  padding-left: 50px;
}
.divcardR3 {
  background: white;

  border: 5px solid #0079c2 !important;
  width: 520px;
}
.logintitle {
  background: #79b91c !important;
}
.logintitleR3 {
  background: #0079c2 !important;
}
.divContainer {
  background: white;
  display: flex;
  overflow: "hidden";
  justify-content: center;
  margin: 20px !important;
  padding-top: 2%;
  height: 90%;
}
.divContainerGrid {
  background: #79b91c;
  display: flex;
  overflow: "hidden";
  justify-content: center;
  margin: 20px;
  border: 1px solid silver;
  height: 85%;
}
.divContainerGridR3 {
  background: #0079c2;
  display: flex;
  overflow: "hidden";
  justify-content: center;
  margin: 20px;
  border: 1px solid silver;
  height: 85%;
}
.footercolor {
  background-color: #1d2f68;
}
.footercolorR3 {
  background-color: #000000;
}

.LinkBar {
  color: #1d2f68;
  background-color: #79b91c;
  padding: 0px 0px;
  font-weight: 900;
  max-width: fit-content;
}
.LinkBarR3 {
  color: #000000;
  background-color: #0079c2;
  padding: 0px 0px;
  font-weight: 900;
  max-width: fit-content;
}

.LinkButton {
  color: #1d2f68;
}

.selectedLink {
  color: #fff !important;
}

.nav-item {
  height: 30px !important;
}
.nav-hide {
  display: none;
}

.nav-show {
  display: block;
}

.nav-line {
  padding-top: 5px;
  color: #fff;
}

.costomerInfo {
  padding: 6px 18px;
  font-size: small;
  color: #1d2f68;
  font-weight: 500;
}
.logoess {
  padding: 18px;
}

.zeroPadding {
  padding: 0px;
}
.footerBunzl {
  /* padding: 15px; */
  padding-bottom: 1px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.contentPage {
  /* height: auto; */
  background-color: #79b91c;
  padding-top: 4px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-bottom: 2px !important;
  border-top: solid;
  height: 98%;
  position: fixed;
}
.contentPageR3 {
  background-color: #0079c2;
  padding-top: 4px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-bottom: 2px !important;
  border-top: solid;
  height: 98%;
  position: fixed;
}
.footerBackGround {
  background-color: #1d2f68;
  color: #fff;
}
.footerBackGroundR3 {
  background-color: #000000;
  color: #fff;
}
.contactpopupfont {
  color: #1d2f68;
  font-weight: bold;
}

.filterControls {
  font-weight: bold;
  font-size: 0.7rem;
  line-height: normal;
  border-radius: 0.2em;
}

.filterButtons {
  width: 80px;
  font-weight: bold !important;
  font-size: 0.7rem !important;
}
.filterButtonsClear {
  width: 100px;
  font-weight: bold !important;
  font-size: 0.7rem !important;
}
.cardNoBorder {
  border: none !important;
}

.gridheader {
  background-image: linear-gradient(#1d2f68, #0072cc);
  color: #fff;
  font-size: 0.7rem !important;
  text-align: left !important;
}
.gridheaderR3 {
  background-image: linear-gradient(#000000, #0072cc);
  color: #fff;
  font-size: 0.7rem !important;
}

.filterBody {
  background-image: linear-gradient(#1d2f68, #0072cc);
  color: #fff;
  padding: 5px;
  /* width: 1090px; */
}
.filterBodyR3 {
  background-image: linear-gradient(#000000, #0072cc);
  color: #fff;
  padding: 5px;
  /* width: 1000px */
}

.ag-paging-panel {
  height: 30px !important;
}
.gridMain .ag-row-hover {
  background-color: #9be133 !important;
}
.gridMainR3 .ag-row-hover {
  background-color: #0079c2 !important;
}
.apGrid .ag-row-hover {
  background-color: #9be133 !important;
  cursor: pointer;
}
.apGridR3 .ag-row-hover {
  background-color: #0079c2 !important;
  cursor: pointer;
}
.apGrid .ag-center-cols-viewport {
  overflow-x: hidden;
}
.apGridR3 .ag-center-cols-viewport {
  overflow-x: hidden;
}
.apGrid .ag-row-selected {
  background-color: #79b91c !important;
}
.apGridR3 .ag-row-selected {
  background-color: #0079c2 !important;
}
.apGrid .ag-root-wrapper{
  border: 0px !important;
}

.apGridR3 .ag-root-wrapper{
  border: 0px !important;
}
.total-row {
  background-color: #808080 !important;
  border-top: solid !important;
  font-weight: bold !important;
  padding-bottom: 0.5rem;
}
.ag-header-cell-label {
  justify-content: center;
}
.searchFilter {
  display: none !important;
}
.img {
  display: block !important;

  background-image: url(../images/magGlass.jpg);
  background-repeat: no-repeat;
}

.empListText {
  margin-left: 500px;
  color: #fff;
  margin-top: 5px;
}

.previoustBtn {
  margin-left: "400px";
}

.custListText {
  margin-left: 20px;
  color: #fff;
  margin-top: 10px;
}
.ag-theme-alpine .ag-row {
  font-size: 0.7rem !important;
  font-weight: 500 !important;
}

.control-inline-label {
  padding-left: calc(0.375rem + 20px);
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  color: #fff;
  background-color: grey !important;
  margin: 0px 2px 0px 2px !important;
}
.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
  background-color: #fff !important;
  margin: 0px 2px 0px 2px !important;
}

.form-check-label {
  font-weight: 500 !important;
}

.dropdown-item:hover {
  color: #16181b !important;
  text-decoration: none;
  background-color: #79b921 !important;
}
.ag-header-cell-text {
  white-space: normal !important;
}
.modal-content
{
  opacity: 0.9;
  background-color: #91a5af !important;
}

.essLoading .modal-content
{
  opacity: 0.9;
  background-color: #007bff !important;
}
.ag-header-cell-label {
  text-overflow: clip;
  overflow: visible;
  white-space: normal;
}