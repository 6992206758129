@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

/* * {
margin: 0;
padding: 0;
box-sizing: border-box;
font-family: "Roboto", sans-serif;
outline: none;
}
html,body{
  width: 100%;
  height: 100%;
}
body {
background-color: #fff;
} */
/* .login-page{
  border-top:8px solid #79B921;
}
.form-holder h4 {
  text-align: center;
  padding: 10px 15px 10px;
  margin: 0px;
  font-weight: 700;
  color: #212529;
  font-size: 24px;
  text-transform: uppercase;
  border-bottom: 3px solid #79b921;
}
.login-box{
position: absolute;
width: 400px;
height: auto;
left:50%;
top:50%;
transform: translate(-50%,-50%);
}
.form-holder{
  border:3px solid #79B921;
  border-radius: 0px;
}
.logo-section{
  text-align: center;
}
.logo-section img{
  width: 80px;
  margin: auto;
}
.logo-section small{
  display: block;
  text-align: center;
  margin: 5px 0px 20px;
  font-size: 14px;
  font-weight: 600;
  color: #002d57;
}
.login-form {
  padding: 10px 15px;
}
.login-form label{
  margin: 0px;
  font-size: 14px;
  color: #666;
}
.login-form .form-group{
  margin-bottom: 10px;
  position: relative;
}
.login-form .form-group .form-control{
  border-radius: 0px;
}
.login-form #pwd{
  padding-right: 50px;
}
.login-form .btn-container{
  padding:0px 15px
}
.login-form .form-group button{
  width:48%;
  text-transform: uppercase;
  float: left;
}
.login-form .btn-primary{
  margin: 0px 1% 0px 0px;
}
.btn-primary{
  background-color: #002d57;
  border:1px solid #002d57;
  color: #fff;
} 
.login-form .btn-o{
  margin: 0px 0px 0px 1%;
}
 .btn-o{
  border:1px solid #002d57;
  color: #002d57;  
} 
.copyright {
  font-size: 12px;
  text-align: center;
  color: #999;
  padding: 20px 0px 0px;
} 
#show{
  opacity: 0;
}
label[for="show"] {
  position: absolute;
  right: 10px;
  bottom: 32px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  color: #002d57;
}

/*inner pages */
.logo img {
  max-width: 100%;
  width: 55px;
  margin-top: 10px;
}
header {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
nav.navbar.navbar-expand-sm.bg-success.navbar-dark {
  display: inline-block;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #79b921 !important;
  padding: 0px 12px;
}
nav.navbar.navbar-expand-sm.bg-success.navbar-dark.r3 {
  display: inline-block;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #0079c2 !important;
  padding: 0px 12px;
}

nav.navbar.navbar-expand-sm.bg-success.navbar-dark .navbar-nav {
  display: inline-block;
}
nav.navbar.navbar-expand-sm.bg-success.navbar-dark ul li {
  display: inline-block;
}
nav.navbar.navbar-expand-sm.bg-success.navbar-dark ul li a {
  position: relative;
  color: #002d57;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.7rem;
  padding: 5px 8px;
}
nav.navbar.navbar-expand-sm.bg-success.navbar-dark.r3 ul li a {
  position: relative;
  color: #000000;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.7rem;
  padding: 5px 8px;
}
nav.navbar.navbar-expand-sm.bg-success.navbar-dark ul li a:hover {
  position: relative;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.7rem;
  padding: 5px 8px;
}
nav.navbar.navbar-expand-sm.bg-success.navbar-dark ul li a:after {
  content: "";
  position: absolute;
  width: 2px;
  right: 0;
  top: 8px;
  height: 0.7rem;
  background-color: #fff;
}
nav.navbar.navbar-expand-sm.bg-success.navbar-dark ul li:last-child a:after {
  width: 0px;
  background-color: #79b921;
}
.nav-section small {
  display: block;
  color: #999;
  margin-top: 5px;
}
.chat {
  margin-top: 10px;
}
.chat-btn {
  background-color: #d7edc6;
  position: relative;
  padding: 0px 10px 0px 50px;
  border-radius: 10px;
  float: right;
}
.chat-btn img {
  position: absolute;
  left: 6px;
  top: 8px;
}
.chat-btn p {
  text-align: left;
  text-transform: uppercase;
  border-left: 1px solid #bfccb5;
  padding: 7px 10px;
  margin: 0px;
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
  color: #002d57;
}
.nav-hide {
  display: none !important;
}

.nav-show {
  display: block;
}

.logoColSpace {
  margin-top: 12px;
  margin-right: 2px;
  padding: 0px;
}
.headerColSpace {
  margin-left: -40px;
  padding: 0px;
}

.loginBox {
  background-color: #79b921 !important;
  padding: 10px;
  margin: 10%;
}
